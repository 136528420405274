import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, InfoBox, OutlinedBox, SubText, TitleText } from "../styles";
import styled from "styled-components";
import CircularLoader from "./CircularLoader";
import { useNavigate } from "react-router-dom";
import { doc, increment, updateDoc } from "firebase/firestore";
import { userContext } from "../context/userContext";
import { auth, db } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
// const DepositOptions = ["Pay to Wallet Address", "Pay With Coinbase Commerce", "PayWithMetamask"]

const ChooseDepositAccountModal = ({ open }) => {
  const { depositModal, setDepositModal } = open;
  const [option, setOption] = useState(false);
  const [coinbase, setCoinbabse] = useState(false);
  const [bitcoin, setBitcoin] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [user] = useAuthState(auth);
  const { dispatch, currentAccount } = useContext(userContext);

  const accountTypes = [
    {
      type: "Practice",
      name: "Practice Account",
      info: "Deposit to your practice account. You will get 0.2 BTC to trade and practice trading with. This balance cannot be withdrawn.",
    },
    {
      type: "Live",
      name: "Live Account",
      info: "Deposit into your actual account. This account can be withdrawn, subscribed from and traded with. Any profits you make with this account are real.",
    },
  ];
  const [showInfo, setShowInfo] = useState(true);
  const navigate = useNavigate();
  const [selected, setSelected] = useState("Practice");

  const incrementPractice = async () => {
    const q = doc(db, "accounts", user.uid);
    try {
      await updateDoc(q, {
        "practice.Crypto.BTC.value": increment(0.2),
      });
    } catch (error) {
      console.log(error);
    }
  };

  function handleSelect(type) {
    switch (type) {
      case "Practice":
        setIsFetching(true);
        dispatch({
          type: "switchAccount",
          payload: "live",
        });
        setTimeout(() => {
          incrementPractice();
        }, 1000);
        setTimeout(() => {
          setDepositModal(false);
          setIsFetching(false);
        }, 2000);
        setDepositModal(false);
        break;
      case "Live":
        setIsFetching(true);
        setTimeout(() => {
          navigate("/deposit");
          setDepositModal(false);
          setIsFetching(false);
        }, 500);
        break;
      default:
        setShowInfo(!showInfo);
        break;
    }
  }

  function handleType(type) {
    // setShowInfo(!showInfo);
    setSelected(type);
  }

  return (
    <Modal
      open={depositModal}
      onClose={() => setDepositModal(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
        outline: "none",
        border: "none",
        // backgroundColor: "gray",
      }}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <TitleText>Select account</TitleText>
          <button
            className="close"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setDepositModal(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              height={10}
              width={10}
            />
          </button>
        </div>

        {option ? (
          <ModalOption>
            <button onClick={() => setOption(false)}>go back</button>
            {option === "Bitcoin" && <p>Bitcoin</p>}
            {option === "Coinbase" && <p>Coinbase</p>}
          </ModalOption>
        ) : isFetching ? (
          <ModalContent>
            {/* <p style={{ placeSelf: "center", padding: "2rem" }}>
              How is the family
            </p> */}
            {/* style={{ placeSelf: "center", padding: "2rem" }} */}
            <CircularLoader />
          </ModalContent>
        ) : (
          <ModalContent>
            <SubText>Select an account to deposit into:</SubText>

            {accountTypes.map((acc) => (
              <InfoBox
                key={acc.type}
                className="box"
                // onClick={() => setOption("Bitcoin")}
                style={{ display: "grid", gap: "1rem" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    className="option"
                    onClick={() => handleSelect(acc.type)}
                  >
                    <button
                      className={
                        acc.type === "Practice"
                          ? "switch practice"
                          : "switch live"
                      }
                    >
                      {acc.type}
                    </button>
                    <p
                      style={{
                        color: acc.type === "Practice" ? "#F7931A" : "#20BCA4",
                      }}
                    >
                      {acc.name}
                    </p>
                  </span>
                  <span style={{ marginLeft: "1rem", position: "relative" }}>
                    <img
                      src="/extraicons/info.svg"
                      alt="info"
                      width={14}
                      onClick={() => handleType(acc.type)}
                    />
                  </span>
                </div>
                {showInfo && selected === acc.type && (
                  <OutlinedBox className="info" style={{ padding: "0.25rem" }}>
                    <p className="infot">{acc.info}</p>
                  </OutlinedBox>
                )}
              </InfoBox>
            ))}

            {/* <Tooltip title="hey" arrow> */}

            {/* </Tooltip> */}
            {/* <p>no</p> */}
            {/* <InfoBox className="box" onClick={() => setOption("Metamask")}>
              <span className="option">
                <button className="switch live">Live</button>
                <p style={{ color: "#20BCA4" }}>Live Account</p>
              </span>
            </InfoBox> */}
          </ModalContent>
        )}
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;
  border: none;
  outline: none;

  .info {
    border: 1px solid #3a4867;
    box-sizing: border-box;
    border-radius: 8px;
    line-height: 14px;

    p {
      font-weight: 500;
      font-size: 13px;
      padding: 1rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .switch {
    border: none;
    text-transform: capitalize;
    font-weight: 600;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .practice {
    color: #f7931a;
    background-color: #403529;
  }

  .live {
    color: #20bca4;
    background-color: #20383c;
  }

  .box {
    background-color: #1f273a;
    border-radius: 4px;
    display: flex;
    place-content: center;
    cursor: pointer;
  }

  .option {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
    /* place-self: center; */
    padding: 0.2rem;
    /* background-color: red; */
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default ChooseDepositAccountModal;
