import styled from "styled-components";
import { sidebarData } from "../static";
import { Link, useNavigate } from "react-router-dom";
import { ProfileItem } from "../styles";
import { auth } from "../firebase/firebase";
import toast from "react-hot-toast";
import { signOut } from "firebase/auth";
import { useContext, useEffect, useRef } from "react";
import { userContext } from "../context/userContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slide from "@mui/material/Slide";

const Sidebar = ({ menu, active, open }) => {
  const currentTab = active;
  const [menuSmall, setMenuSmall] = menu;
  const [isOpen, setIsOpen] = open;
  const navigate = useNavigate();
  const { dispatch } = useContext(userContext);
  const { userData } = useContext(userContext);
  const { firstname, lastname } = userData;
  const mobile = useMediaQuery("(max-width: 900px)");
  const sidebarRef = useRef();

  useEffect(() => {
    if (!mobile) return;
    const handler = (e) => {
      if (!sidebarRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [sidebarRef, mobile]);

  function toggleMenu() {
    setMenuSmall(!menuSmall);
  }

  function handleLogout() {
    try {
      dispatch({ type: "switchAccount", payload: "live" });
      dispatch({ type: "logout" });
      signOut(auth);
      navigate("/login");
      toast.success("Logged Out");
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {}, 800);
  }

  return (
    <Slide direction="right" in={isOpen} onChange={() => setIsOpen(!isOpen)}>
      <MobileSidebar
        ref={sidebarRef}
        style={{ display: isOpen === true ? "flex" : "none" }}
        className={!menuSmall && "rightpadding"}
      >
        <div className="up">
          <div className="top">
            <div
              className="logoWrap"
              style={{ display: menuSmall ? "none" : "flex" }}
            >
              <img src="/logo.png" alt="logo" className="logo" />
            </div>

            <span className={menuSmall ? "burger burgerMobile" : "burger"}>
              {mobile ? (
                <img
                  src="/sidebaricons/close.svg"
                  alt="close"
                  className="close"
                  onClick={() => setIsOpen(false)}
                />
              ) : (
                <img
                  src="/sidebaricons/hamburger.svg"
                  alt="hamburger"
                  className="hamburger"
                  onClick={toggleMenu}
                />
              )}
            </span>
          </div>

          <div className="mid">
            {sidebarData.map((side) => (
              <Link to={side.link} key={side.name} className="link">
                <SidebarItem
                  className={side.name === currentTab && "active"}
                  style={{ placeContent: menuSmall && "center" }}
                >
                  <img src={side.src} alt={side.name} />
                  <p style={{ display: menuSmall ? "none" : "" }}>
                    {side.name}
                  </p>
                </SidebarItem>
              </Link>
            ))}

            {userData.admin && (
              <Link to="/manage" className="link">
                <SidebarItem
                  className={"Manage" === currentTab && "active"}
                  style={{ placeContent: menuSmall && "center" }}
                >
                  <img src="/sidebaricons/manage.svg" alt="manage" />
                  <p style={{ display: menuSmall ? "none" : "" }}>Manage</p>
                </SidebarItem>
              </Link>
            )}
          </div>
        </div>

        <div className="bottom">
          <Link to="/profile" className="link">
            <div>
              <div
                style={{ color: "white", backgroundColor: "#1f273a" }}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 mt-4"
              >
                <div className="flex-shrink-0">
                  {userData.photoURL ? (
                    <img
                      className="profile"
                      src={userData?.photoURL}
                      alt="profile"
                    />
                  ) : (
                    <span className="avatar">
                      <>
                        {!userData.firstname && (
                          <img src="/svg-loaders/tail-spin.svg" alt="loading" />
                        )}
                        {userData.firstname?.slice(0, 1)}
                      </>
                    </span>
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <span className="absolute inset-0" aria-hidden="true"></span>
                  <p className="text-sm font-medium text-white">
                    {firstname && firstname} {lastname && lastname}
                  </p>
                  {userData.accountType && (
                    <p className="text-sm text-[#A3A3A3] truncate font-medium">
                      {userData.accountType} Account
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* <ProfileItem>
              {userData ? (
                <>
                  {userData.photoURL ? (
                    <img
                      src={userData?.photoURL}
                      alt="profile"
                      className="profile"
                    />
                  ) : (
                    <span className="avatar">
                      <>
                        {!userData.firstname && (
                          <img src="/svg-loaders/tail-spin.svg" alt="loading" />
                        )}
                        {userData.firstname?.slice(0, 1)}
                      </>
                    </span>
                  )}
                  <p
                    style={{
                      display: menuSmall ? "none" : "",
                    }}
                  >
                    {firstname && firstname} {lastname && lastname}
                  </p>
                </>
              ) : (
                <img
                  src="/svg-loaders/tail-spin.svg"
                  alt="loading"
                  width={24}
                  height={24}
                />
              )}
            </ProfileItem> */}
          </Link>

          <SidebarItem
            onClick={handleLogout}
            style={{ placeContent: menuSmall && "center" }}
          >
            <img src="/sidebaricons/logout.svg" alt="logout" />
            <p style={{ display: menuSmall ? "none" : "" }}>Logout</p>
          </SidebarItem>
        </div>
      </MobileSidebar>
    </Slide>
  );
};

const MobileSidebar = styled.div`
  background: #131824;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: max-content;
  box-sizing: border-box;
  padding: 1rem;
  border-right: 1px solid rgba(31, 39, 58, 0.328);
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 19px;
  transition: display 300ms ease-in-out;

  &.rightpadding {
    padding-right: 1.3rem;
  }

  @media screen and (max-width: 900px) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100vh;

    .exit {
      display: flex;
      cursor: pointer;
    }
  }

  .avatar {
    width: 35px;
    place-content: center;
    height: 35px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
  }

  .link {
    text-decoration: none;
  }

  img {
    width: 18px;
    height: 18px;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .burger {
      display: flex;
      border-radius: 4px;
      transition: background-color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

      &.burgerMobile {
        width: 100%;
        place-content: center;
      }
    }

    .burger:hover {
      background-color: rgba(214, 214, 214, 0.12);
    }

    .hamburger {
      padding: 0.5rem;
      cursor: pointer;
    }

    .close {
      width: 13px;
      /* height: 13px; */
      cursor: pointer;
      padding: 0.5rem;
      cursor: pointer;
    }
  }

  .logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .logoWrap {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .up {
    display: grid;
  }

  .mid {
    margin-top: 50px;

    .active {
      background-color: #1199fa;
      border-radius: 8px;
    }
  }

  .bottom {
    /* margin-top: 50px; */
    gap: 1rem;

    @media screen and (max-width: 1000px) {
      margin-bottom: 50px;
    }
  }

  .mid,
  .bottom {
    display: grid;
  }
`;

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 14px;
  width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateX(20px);
  }
`;

export default Sidebar;
