import styled from "styled-components";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Content,
  Card,
  TitleText,
  OutlinedButton,
  OutlinedBox,
  ActionButtons,
  Switches,
  PageSwitcher,
} from "../styles";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../firebase/firebase";
import Loader from "./Loader";
import { useMediaQuery } from "@mui/material";
import toast from "react-hot-toast";
import { userContext } from "../context/userContext";
import {
  AdvancedRealTimeChart,
  SymbolOverview,
} from "react-ts-tradingview-widgets";
import TradeHistory from "../usertables/TradeHistory";
import { Trade } from "../components";
import { doc, onSnapshot } from "firebase/firestore";

const Trading = () => {
  const params = useParams();
  const { type, asset } = params;
  const [active, setActive] = useState(asset);
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const small = useMediaQuery("(max-width: 768px)");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);

  const { accounts, currentPrices, userData, addresses } =
    useContext(userContext);
  const [liveAccounts, setLiveAccounts] = useState([]);

  const tradeTypes = ["Crypto", "Forex", "Stocks"];
  const [tradeType, setTradeType] = useState("Crypto");
  const [action, setAction] = useState("buy");
  const [didReset, setDidReset] = useState("");
  const [chartType, setChartType] = useState("Technical");
  const { currentAccount, dispatch } = useContext(userContext);
  const technicalButton = useRef();
  const simpleButton = useRef();

  const [activePage, setActivePage] = useState("Trade");
  const pages = ["Trade", "History"];

  function switchAccount() {
    dispatch({
      type: "switchAccount",
      payload: currentAccount === "live",
    });
    setDidReset("true");
  }

  const stockassets = [
    { name: "Apple", symbol: "AAPL" },
    { name: "Abbot Labs", symbol: "ABT" },
    { name: "Adobe", symbol: "ADBE" },
    { name: "Analog Devices", symbol: "ADI" },
    { name: "Aethlon Medical", symbol: "AEMD" },
    { name: "American International Group", symbol: "AIG" },
    { name: "AMC Holdings", symbol: "AMC" },
    { name: "AMD", symbol: "AMD" },
    { name: "American Tower", symbol: "AMT" },
    { name: "Amazon", symbol: "AMZN" },
    { name: "Alpha Pro Tech", symbol: "APT" },
    { name: "ASML", symbol: "ASML" },
    { name: "Aterian Inc", symbol: "ATER" },
    { name: "American Express", symbol: "AXP" },

    { name: "Boeing", symbol: "BA" },
    { name: "Alibaba", symbol: "BABA" },
    { name: "Bank of America", symbol: "BAC" },
    { name: "Baidu Inc", symbol: "BAIDU" },
    { name: "Bristol Myers", symbol: "BMY" },

    { name: "Citigroup", symbol: "C" },
    { name: "Caterpillar", symbol: "CAT" },
    { name: "Clear Channel Outdoor", symbol: "CCO" },
    { name: "Camber Energy", symbol: "CEI" },
    { name: "Chewy Inc", symbol: "CHWY" },
    { name: "Colgate-Palmolive", symbol: "CL" },
    { name: "Comcast", symbol: "CMCSA" },
    { name: "Costco", symbol: "COST" },
    { name: "Cardiff Oncology Inc", symbol: "CRDF" },
    { name: "Salesforce Inc", symbol: "CRM" },
    { name: "Cisco", symbol: "CSCO" },
    { name: "Chevron", symbol: "CVX" },

    { name: "Disney", symbol: "DIS" },

    { name: "Ebay", symbol: "EBAY" },

    { name: "Meta Platforms Inc", symbol: "FB" },
    { name: "Fastly Inc", symbol: "FSLY" },

    { name: "General Electric", symbol: "GE" },
    { name: "Gevo Inc", symbol: "GEVO" },
    { name: "General Motors", symbol: "GM" },
    { name: "Google", symbol: "GOOGL" },
    { name: "Goldman Sachs", symbol: "GS" },

    { name: "Home Depot", symbol: "HD" },
    { name: "Honeywell", symbol: "HON" },

    { name: "IBM", symbol: "IBM" },
    { name: "Inmode", symbol: "INMD" },
    { name: "Intel", symbol: "INTC" },

    { name: "Johnson & Johnson", symbol: "JNJ" },
    { name: "JP Morgain", symbol: "JP Morgan" },

    { name: "Coca Cola", symbol: "KO" },

    { name: "Lennar Corporation", symbol: "LEN" },
    { name: "Las vegas Sands", symbol: "LVS" },

    { name: "MasterCard", symbol: "MA" },
    { name: "Mondelez", symbol: "MDLZ" },
    { name: "3M Company", symbol: "MMM" },
    { name: "Monster", symbol: "MNST" },
    { name: "Attria Group", symbol: "MO" },
    { name: "Marin Software", symbol: "MRIN" },
    { name: "Merck", symbol: "MRK" },
    { name: "Morgan Stanley", symbol: "MS" },
    { name: "Microsoft", symbol: "MSFT" },
    { name: "Motorola", symbol: "MSI" },

    { name: "Netflix", symbol: "NFLX" },
    { name: "Nike", symbol: "NKE" },
    { name: "Nvidia", symbol: "NVDA" },
    { name: "Novartis", symbol: "NVS" },

    { name: "Oracle", symbol: "ORCL" },

    { name: "Pepsico", symbol: "PEP" },
    { name: "Pfizer", symbol: "PFE" },
    { name: "Procter & Gamble", symbol: "PG" },
    { name: "PayPal", symbol: "PYPL" },

    { name: "Ferrari", symbol: "RACE" },
    { name: "Rocket Lab", symbol: "RKLB" },
    { name: "Ralph lauren", symbol: "RL" },
    { name: "Rewalk Robotics", symbol: "RWLK" },

    { name: "Starbucks", symbol: "SBUX" },
    { name: "SSR Mining", symbol: "SSRM" },
    { name: "Square", symbol: "SQ" },

    { name: "At&t", symbol: "T" },
    { name: "Teva", symbol: "TEVA" },
    { name: "Toyota Motor", symbol: "TM" },
    { name: "T-Mobile", symbol: "TMUS" },
    { name: "TripAdvisor", symbol: "TRIP" },
    { name: "Tesla", symbol: "TSLA" },
    { name: "TSMC", symbol: "TSM" },
    { name: "Twitter", symbol: "TWTR" },

    { name: "United Health Group", symbol: "UNH" },

    { name: "Visa", symbol: "V" },
    { name: "Verizon", symbol: "VZ" },
    { name: "Wells Fargo", symbol: "WFC" },

    { name: "Walmart", symbol: "WMT" },

    { name: "Exxon Mobil", symbol: "XOM" },
  ];

  const cryptoassets = [
    // { name: "1nch Protocol", symbol: "1INCHUSD" },

    { name: "Aave", symbol: "AAVEUSD", alt: "AAVE" },
    { name: "Cardano", symbol: "ADAUSD", alt: "ADA" },
    { name: "Algorand", symbol: "ALGOUSD", alt: "ALGO" },
    { name: "Anchor Protocol", symbol: "ANCUSD", alt: "ANCUSD" },
    { name: "Apecoin", symbol: "APEUSD", alt: "APE" },
    { name: "Aurora", symbol: "AURORAUSD", alt: "AURORAUSD" },
    { name: "Avalanche", symbol: "AVAXUSD", alt: "AVAX" },
    { name: "Axie Infinity", symbol: "AXSUSD", alt: "AXS" },

    { name: "Bitcoin Cash", symbol: "BCHUSD", alt: "BCH" },
    { name: "Boring DAO", symbol: "BORINGUSD", alt: "BORINGUSDT" },
    { name: "Bitcoin", symbol: "BTCUSD", alt: "BTC" },
    { name: "Bitcoin Gold", symbol: "BTGUSD", alt: "BTGUSD" },

    { name: "Comdex", symbol: "CMDXUSD", alt: "CMDX" },
    { name: "Cronos", symbol: "CROUSD", alt: "CROUSD" },

    { name: "Dai", symbol: "DAIUSD", alt: "DAI" },
    // { name: "Dao Maker", symbol: "DAOUSD" },
    { name: "Dash", symbol: "DASHUSD", alt: "DASHUSD" },
    { name: "Defi Land", symbol: "DFLUSD", alt: "DFLUSD" },
    { name: "Polkadot", symbol: "DOTUSD", alt: "DOT" },
    { name: "Dogecoin", symbol: "DOGEUSD", alt: "DOGE" },

    { name: "Evmos", symbol: "EVMOSUSD", alt: "EVMOSUSDT" },
    { name: "Ethereum Classic", symbol: "ETCUSD", alt: "ETC" },
    { name: "Ethereum", symbol: "ETHUSD", alt: "ETH" },

    { name: "Stepn", symbol: "GMTUSD", alt: "STEPUSD" },
    { name: "Gochain", symbol: "GOUSD", alt: "GOUSD" },
    { name: "The Graph", symbol: "GRTUSD", alt: "GRTUSD" },
    { name: "Gate Token", symbol: "GTUSD", alt: "GTUSD" },

    { name: "ChainLink", symbol: "LINKUSD", alt: "LINK" },
    { name: "Link", symbol: "LNUSD", alt: "LNUSDT" },
    { name: "Litecoin", symbol: "LTCUSD", alt: "LTC" },

    { name: "Decentraland", symbol: "MANAUSD", alt: "MANA" },
    { name: "Matic", symbol: "MATICUSD", alt: "MATIC" },

    { name: "Nexo", symbol: "NEXOUSD", alt: "NEXPUSD" },
    { name: "Energi", symbol: "NRGUSD", alt: "NRGUSDT" },

    { name: "Origin Protocol", symbol: "OGNUSD", alt: "OGNUSDT" },
    { name: "Okb", symbol: "OKBUSD", alt: "OKBUSDT" },
    { name: "Optimism", symbol: "OPUSD", alt: "OPUSDT" },
    { name: "Orion Protocol", symbol: "ORNUSD", alt: "ORNUSDT" },

    { name: "Raini", symbol: "RAINIUSD", alt: "RAINIUSD_165C19" },
    { name: "Rarible", symbol: "RARIUSD", alt: "RARIUSD" },

    { name: "Safepal", symbol: "SFPUSD", alt: "SFPUSDT" },
    { name: "Shiba Inu", symbol: "SHIBUSD", alt: "SHIB" },
    { name: "Step Finance", symbol: "STEPUSD", alt: "STEPUSD" },
    { name: "Sushi", symbol: "SUSHIUSD", alt: "SUSHI" },

    // { name: "Wonderland", symbol: "TIMEUSD" },
    { name: "Telos", symbol: "TLOSUSD", alt: "TLOSUSD" },
    { name: "Tron", symbol: "TRXUSD", alt: "TRX" },

    { name: "Solana", symbol: "SOLUSD", alt: "SOL" },

    { name: "Uniswap", symbol: "UNIUSD", alt: "UNIUSD" },
    { name: "USD Coin", symbol: "USDCUSD", alt: "USDC" },
    { name: "Tether", symbol: "USDTUSD", alt: "USDT" },

    { name: "VeChain", symbol: "VETUSD", alt: "VETUSD" },

    { name: "Wing Finance", symbol: "WINGUSD", alt: "WINGUSD" },

    { name: "Chain", symbol: "XCNUSD", alt: "XCNUSD" },
    { name: "Stellar", symbol: "XLMUSD", alt: "XLM" },
    { name: "Monero", symbol: "XMRUSD", alt: "XMR" },
    { name: "Proton", symbol: "XPRUSD", alt: "XPRUSDT" },
    { name: "Tezos", symbol: "XTZUSD", alt: "XTZ" },

    { name: "Zcash", symbol: "ZECUSD", alt: "ZEXUSDT" },
    { name: "Zignaly", symbol: "ZIGUSD", alt: "ZIGUSDT" },
  ];

  useEffect(() => {
    if (document.querySelector("iframe")) {
      if (chartType === "Simple") {
        const url = `https://s.tradingview.com/mediumwidgetembed/?symbols=${active}&fontFamily=Trebuchet%20MS%2C%20sans-serif&wickDownColor=%23ef5350&wickUpColor=%2326a69a&borderDownColor=%23ef5350&borderUpColor=%2326a69a&downColor=%23ef5350&upColor=%2326a69a&chartType=candlesticks&scaleMode=Normal&scalePosition=no&locale=en&fontColor=%23787B86&gridLineColor=rgba(42%2C%2046%2C%2057%2C%200)&width=100%25&height=100%25&colorTheme=dark&utm_source=localhost&utm_medium=widget&utm_campaign=symbol-overview&showFloatingTooltip=1`;
        document.querySelector("iframe").setAttribute("src", url);
      }

      if (chartType === "Technical") {
        const url = `https://s.tradingview.com/widgetembed/?frameElementId=tradingview_07f17&symbol=${active}&interval=1&hidelegend=1&hidesidetoolbar=1&symboledit=1&saveimage=1&toolbarbg=f1f3f6&studies=%5B%5D&theme=dark&style=1&timezone=UTC&withdateranges=1&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=localhost&utm_medium=widget&utm_campaign=chart&utm_term=${active}`;
        document.querySelector("iframe").setAttribute("src", url);
      }
    }
  });

  const symbols = [active];

  useEffect(() => {
    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Stock, Crypto, Fiat } = live;
        const StockAccount = Object.values(Stock);
        const CryptoAccount = Object.values(Crypto);
        setLiveAccounts([...CryptoAccount, ...StockAccount, Fiat]);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (!loading && user) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    if (user) {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        const data = doc.data();

        if (data && !data.verified) {
          navigate("/newverify");
          toast.error("Verify your account to continue.");
        }
      });
    }
  }, [user]);

  function handleChartType(e) {
    setChartType(e.target.value);
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Trade"
            open={[isOpen, setIsOpen]}
          />

          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              {/* <TitleText>Trade</TitleText> */}
              <PageSwitcher className="switcher scrollbar-hidden">
                {pages.map((page) => (
                  <button
                    style={{ padding: "0.8rem" }}
                    key={page}
                    value={page}
                    className={page === activePage ? "active" : ""}
                    onClick={(e) => setActivePage(e.target.value)}
                  >
                    {page}
                  </button>
                ))}
              </PageSwitcher>

              <button
                className="actionButton mt-8 cursor-pointer"
                style={{ maxWidth: "max-content" }}
                onClick={() => navigate("/markets")}
              >
                <p className="actionText">Go back</p>
              </button>

              {activePage === "Trade" && (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: mobile ? "auto" : "1fr 1fr",
                    gap: "32px",
                    height: "fit-content",
                    zIndex: "-2",
                    marginTop: "32px",
                    paddingBottom: "90px",
                  }}
                >
                  {chartType === "Technical" && (
                    <div
                      className="contentBody md:h-[100%] "
                      id="chart__container__technical"
                      style={{
                        display: "grid",
                        gap: "0.7rem",
                        height: mobile ? "400px" : "100%",
                      }}
                    >
                      <AdvancedRealTimeChart
                        height={mobile ? "400px" : "100%"}
                        width="100%"
                        symbol={active}
                        theme="dark"
                        hide_side_toolbar="true"
                        hide_legend="true"
                        container_id="chart__container__technical"
                      ></AdvancedRealTimeChart>
                    </div>
                  )}

                  {chartType === "Simple" && (
                    <div
                      className="contentBody md:h-[100%] "
                      id="chart__container__simple"
                      style={{
                        display: "grid",
                        gap: "0.7rem",
                        height: mobile ? "400px" : "100%",
                      }}
                    >
                      <SymbolOverview
                        colorTheme="dark"
                        symbols={symbols}
                        height={mobile ? "400px" : "100%"}
                        width="100%"
                        chartType="candlesticks"
                        container_id="chart__container__simple"
                      />
                    </div>
                  )}

                  <div>
                    <Switches className="scrollbar-hidden switches px-2 py-4 pl-2 md:max-w-[400px]">
                      <button
                        value="Technical"
                        ref={technicalButton}
                        className={
                          chartType === "Technical" ? "active" : "switch"
                        }
                        onClick={handleChartType}
                      >
                        Technical
                      </button>

                      <button
                        value="Simple"
                        ref={simpleButton}
                        className={chartType === "Simple" ? "active" : "switch"}
                        onClick={handleChartType}
                      >
                        Simple
                      </button>
                    </Switches>

                    <Trade asset={{ active, setActive }} />
                  </div>

                  {/* <Card>
                    <SubText className="top">Place a trade</SubText>
                    <SubmitContent style={{ display: "grid", gap: "32px" }}>
                      <Switches className="scrollbar-hidden switches">
                        <button
                          value="Technical"
                          ref={technicalButton}
                          className={
                            chartType === "Technical" ? "active" : "switch"
                          }
                          onClick={handleChartType}
                        >
                          Technical
                        </button>

                        <button
                          value="Simple"
                          ref={simpleButton}
                          className={
                            chartType === "Simple" ? "active" : "switch"
                          }
                          onClick={handleChartType}
                        >
                          Simple
                        </button>
                      </Switches>

                      <ActionButtons>
                        <button
                          value="buy"
                          className={action === "buy" ? "action buy" : "action"}
                          onClick={(e) => {
                            setAction(e.target.value);
                          }}
                        >
                          BUY
                        </button>
                        <button
                          value="sell"
                          className={
                            action === "sell" ? "action sell" : "action"
                          }
                          onClick={(e) => {
                            setAction(e.target.value);
                          }}
                        >
                          SELL
                        </button>
                      </ActionButtons>

                      <OutlinedBox style={{ display: "flex" }}>
                        <p className="infot">
                          Your current account is {currentAccount}.
                          <span
                            className="clickable"
                            style={{ display: "inline", marginLeft: "0.3rem" }}
                            onClick={switchAccount}
                          >
                            Switch
                          </span>
                        </p>
                      </OutlinedBox>

                      <div className="selectWrap">
                        <label htmlFor="type">Type</label>
                        <div className="selectBox">
                          <div className="selectTop">
                            <select name="type" onChange={handleTypeChange}>
                              {tradeTypes.map((type) => (
                                <option key={type}>{type}</option>
                              ))}
                            </select>
                            <img src="/extraicons/arrowdown.svg" alt="select" />
                          </div>
                        </div>
                      </div>

                      {tradeType === "Crypto" && (
                        <Crypto
                          action={action}
                          type={{ active, setActive }}
                          assets={cryptoassets}
                        />
                      )}
                      {tradeType === "Forex" && (
                        <Forex action={action} didReset={didReset} />
                      )}
                      {tradeType === "Stocks" && (
                        <Stocks
                          action={action}
                          assets={stockassets}
                          type={{ active, setActive }}
                        />
                      )}
                    </SubmitContent>
                  </Card> */}
                </div>
              )}

              {activePage === "History" && <TradeHistory user={user} />}
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }


  .sel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  .btn {
    border: 1px solid #1f273a;
    color: white;
  }

  .btn:hover {
    background-color: #1f273a;
    color: #119ffa;
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .proofContainer {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .chooseFileBox {
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      display: grid;
      grid-template-columns: 124px auto;
      align-items: center;

      button {
        background: #1f273a;
        border-radius: 4px;
        border: none;
        margin: 2px;
        cursor: pointer;
        height: 36px;

        p {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          padding: 10px;
          color: #ffffff;
        }
      }

      span {
        display: flex;
        /* text-align: center; */
        min-width: 100%;
        /* place-content: center; */
        margin-left: 10px;
        font-weight: 600;
        font-size: 15px;
        line-height: 16px;
        color: #a3a3a3;
      }
    }
  }

  .activeBox {
    height: 100%;
  }

  .button {
    margin-top: 25px;
  }
`;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  box-sizing: border-box;
  margin-bottom: 20px;
  /* padding-bottom: 60px; */
`;

const SubText = styled.p`
  font-weight: 600;
  color: #a3a3a3;
`;

const BodyText = styled.p`
  color: #c9c9c9;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  box-sizing: border-box;
  gap: 1rem;
  width: 100%;
  height: fit-content;
  margin-top: 50px;
  padding-bottom: 60px;

  @media screen and (max-width: 1500px) {
    display: grid;
    grid-template-columns: auto auto;
  }

  @media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: auto;
  }

  .body {
    display: grid;
    gap: 1.7rem;
  }

  .contentBody {
    /* /* max-width: 360px; */
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    place-items: center;
    /* gap: 0.5rem; */
    padding: 0.5rem;
    box-sizing: border-box;
  }

  .methodBox {
    background-color: #161c2a;
    padding: 0.84rem;
    border-radius: 0.5rem;
    color: white;
    display: grid;
    gap: 1rem;
    text-align: left;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;
const ContentContainer = styled.div`
  max-width: 360px;
  text-align: left;
  display: grid;
  gap: 1rem;
  padding: 1rem;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const SubmitContent = styled.div`
  border-radius: 0.5rem;
  background-color: #161c2a;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  place-content: center;
`;

export default Trading;
