import styled from "styled-components";
import { useContext, useState } from "react";
import {
  ActionButtons,
  Card,
  OutlinedBox,
  Switches,
  TitleText,
} from "../styles";
// import { userContext } from "../context/userContext";
// import axios from "axios";
import Crypto from "./trades/Crypto";
import Stocks from "./trades/Stocks";
import Forex from "./trades/Forex";
import { userContext } from "../context/userContext";

const NewTrade = () => {
  // const panelOptions = ["Trade", "Convert"];
  // const [activePanel, setActivePanel] = useState("Trade");
  const tradeTypes = ["Crypto", "Forex", "Stocks"];
  // const tradeTypes = ["Crypto", "Stocks"];
  const [tradeType, setTradeType] = useState("Crypto");
  const [action, setAction] = useState("buy");
  const [didReset, setDidReset] = useState("");
  // const actions = ["Buy", "Sell"];

  const { currentAccount, dispatch } = useContext(userContext);

  function switchAccount() {
    dispatch({
      type: "switchAccount",
      payload: currentAccount === "live",
    });
    setDidReset("true");
  }

  const stockassets = [
    { name: "Apple", symbol: "AAPL" },
    { name: "Abbot Labs", symbol: "ABT" },
    { name: "Adobe", symbol: "ADBE" },
    { name: "Analog Devices", symbol: "ADI" },
    { name: "Aethlon Medical", symbol: "AEMD" },
    { name: "American International Group", symbol: "AIG" },
    { name: "AMC Holdings", symbol: "AMC" },
    { name: "AMD", symbol: "AMD" },
    { name: "American Tower", symbol: "AMT" },
    { name: "Amazon", symbol: "AMZN" },
    { name: "Alpha Pro Tech", symbol: "APT" },
    { name: "ASML", symbol: "ASML" },
    { name: "Aterian Inc", symbol: "ATER" },
    { name: "American Express", symbol: "AXP" },

    { name: "Boeing", symbol: "BA" },
    { name: "Alibaba", symbol: "BABA" },
    { name: "Bank of America", symbol: "BAC" },
    { name: "Baidu Inc", symbol: "BAIDU" },
    { name: "Bristol Myers", symbol: "BMY" },

    { name: "Citigroup", symbol: "C" },
    { name: "Caterpillar", symbol: "CAT" },
    { name: "Clear Channel Outdoor", symbol: "CCO" },
    { name: "Camber Energy", symbol: "CEI" },
    { name: "Chewy Inc", symbol: "CHWY" },
    { name: "Colgate-Palmolive", symbol: "CL" },
    { name: "Comcast", symbol: "CMCSA" },
    { name: "Costco", symbol: "COST" },
    { name: "Cardiff Oncology Inc", symbol: "CRDF" },
    { name: "Salesforce Inc", symbol: "CRM" },
    { name: "Cisco", symbol: "CSCO" },
    { name: "Chevron", symbol: "CVX" },

    { name: "Disney", symbol: "DIS" },

    { name: "Ebay", symbol: "EBAY" },

    { name: "Meta Platforms Inc", symbol: "FB" },
    { name: "Fastly Inc", symbol: "FSLY" },

    { name: "General Electric", symbol: "GE" },
    { name: "Gevo Inc", symbol: "GEVO" },
    { name: "General Motors", symbol: "GM" },
    { name: "Google", symbol: "GOOGL" },
    { name: "Goldman Sachs", symbol: "GS" },

    { name: "Home Depot", symbol: "HD" },
    { name: "Honeywell", symbol: "HON" },

    { name: "IBM", symbol: "IBM" },
    { name: "Inmode", symbol: "INMD" },
    { name: "Intel", symbol: "INTC" },

    { name: "Johnson & Johnson", symbol: "JNJ" },
    { name: "JP Morgain", symbol: "JP Morgan" },

    { name: "Coca Cola", symbol: "KO" },

    { name: "Lennar Corporation", symbol: "LEN" },
    { name: "Las vegas Sands", symbol: "LVS" },

    { name: "MasterCard", symbol: "MA" },
    { name: "Mondelez", symbol: "MDLZ" },
    { name: "3M Company", symbol: "MMM" },
    { name: "Monster", symbol: "MNST" },
    { name: "Attria Group", symbol: "MO" },
    { name: "Marin Software", symbol: "MRIN" },
    { name: "Merck", symbol: "MRK" },
    { name: "Morgan Stanley", symbol: "MS" },
    { name: "Microsoft", symbol: "MSFT" },
    { name: "Motorola", symbol: "MSI" },

    { name: "Netflix", symbol: "NFLX" },
    { name: "Nike", symbol: "NKE" },
    { name: "Nvidia", symbol: "NVDA" },
    { name: "Novartis", symbol: "NVS" },

    { name: "Oracle", symbol: "ORCL" },

    { name: "Pepsico", symbol: "PEP" },
    { name: "Pfizer", symbol: "PFE" },
    { name: "Procter & Gamble", symbol: "PG" },
    { name: "PayPal", symbol: "PYPL" },

    { name: "Ferrari", symbol: "RACE" },
    { name: "Rocket Lab", symbol: "RKLB" },
    { name: "Ralph lauren", symbol: "RL" },
    { name: "Rewalk Robotics", symbol: "RWLK" },

    { name: "Starbucks", symbol: "SBUX" },
    { name: "SSR Mining", symbol: "SSRM" },
    { name: "Square", symbol: "SQ" },

    { name: "At&t", symbol: "T" },
    { name: "Teva", symbol: "TEVA" },
    { name: "Toyota Motor", symbol: "TM" },
    { name: "T-Mobile", symbol: "TMUS" },
    { name: "TripAdvisor", symbol: "TRIP" },
    { name: "Tesla", symbol: "TSLA" },
    { name: "TSMC", symbol: "TSM" },
    { name: "Twitter", symbol: "TWTR" },

    { name: "United Health Group", symbol: "UNH" },

    { name: "Visa", symbol: "V" },
    { name: "Verizon", symbol: "VZ" },
    { name: "Wells Fargo", symbol: "WFC" },

    { name: "Walmart", symbol: "WMT" },

    { name: "Exxon Mobil", symbol: "XOM" },
  ];

  const cryptoassets = [
    // { name: "1nch Protocol", symbol: "1INCHUSD" },

    { name: "Aave", symbol: "AAVEUSD", alt: "AAVE" },
    { name: "Cardano", symbol: "ADAUSD", alt: "ADA" },
    { name: "Algorand", symbol: "ALGOUSD", alt: "ALGO" },
    { name: "Anchor Protocol", symbol: "ANCUSD", alt: "ANCUSD" },
    { name: "Apecoin", symbol: "APEUSD", alt: "APE" },
    { name: "Aurora", symbol: "AURORAUSD", alt: "AURORAUSD" },
    { name: "Avalanche", symbol: "AVAXUSD", alt: "AVAX" },
    { name: "Axie Infinity", symbol: "AXSUSD", alt: "AXS" },

    { name: "Bitcoin Cash", symbol: "BCHUSD", alt: "BCH" },
    { name: "Boring DAO", symbol: "BORINGUSD", alt: "BORINGUSDT" },
    { name: "Bitcoin", symbol: "BTCUSD", alt: "BTC" },
    { name: "Bitcoin Gold", symbol: "BTGUSD", alt: "BTGUSD" },

    { name: "Comdex", symbol: "CMDXUSD", alt: "CMDX" },
    { name: "Cronos", symbol: "CROUSD", alt: "CROUSD" },

    { name: "Dai", symbol: "DAIUSD", alt: "DAI" },
    // { name: "Dao Maker", symbol: "DAOUSD" },
    { name: "Dash", symbol: "DASHUSD", alt: "DASHUSD" },
    { name: "Defi Land", symbol: "DFLUSD", alt: "DFLUSD" },
    { name: "Polkadot", symbol: "DOTUSD", alt: "DOT" },
    { name: "Dogecoin", symbol: "DOGEUSD", alt: "DOGE" },

    { name: "Evmos", symbol: "EVMOSUSD", alt: "EVMOSUSDT" },
    { name: "Ethereum Classic", symbol: "ETCUSD", alt: "ETC" },
    { name: "Ethereum", symbol: "ETHUSD", alt: "ETH" },

    { name: "Stepn", symbol: "GMTUSD", alt: "STEPUSD" },
    { name: "Gochain", symbol: "GOUSD", alt: "GOUSD" },
    { name: "The Graph", symbol: "GRTUSD", alt: "GRTUSD" },
    { name: "Gate Token", symbol: "GTUSD", alt: "GTUSD" },

    { name: "ChainLink", symbol: "LINKUSD", alt: "LINK" },
    { name: "Link", symbol: "LNUSD", alt: "LNUSDT" },
    { name: "Litecoin", symbol: "LTCUSD", alt: "LTC" },

    { name: "Decentraland", symbol: "MANAUSD", alt: "MANA" },
    { name: "Matic", symbol: "MATICUSD", alt: "MATIC" },

    { name: "Nexo", symbol: "NEXOUSD", alt: "NEXPUSD" },
    { name: "Energi", symbol: "NRGUSD", alt: "NRGUSDT" },

    { name: "Origin Protocol", symbol: "OGNUSD", alt: "OGNUSDT" },
    { name: "Okb", symbol: "OKBUSD", alt: "OKBUSDT" },
    { name: "Optimism", symbol: "OPUSD", alt: "OPUSDT" },
    { name: "Orion Protocol", symbol: "ORNUSD", alt: "ORNUSDT" },

    { name: "Raini", symbol: "RAINIUSD", alt: "RAINIUSD_165C19" },
    { name: "Rarible", symbol: "RARIUSD", alt: "RARIUSD" },

    { name: "Safepal", symbol: "SFPUSD", alt: "SFPUSDT" },
    { name: "Shiba Inu", symbol: "SHIBUSD", alt: "SHIB" },
    { name: "Step Finance", symbol: "STEPUSD", alt: "STEPUSD" },
    { name: "Sushi", symbol: "SUSHIUSD", alt: "SUSHI" },

    // { name: "Wonderland", symbol: "TIMEUSD" },
    { name: "Telos", symbol: "TLOSUSD", alt: "TLOSUSD" },
    { name: "Tron", symbol: "TRXUSD", alt: "TRX" },

    { name: "Solana", symbol: "SOLUSD", alt: "SOL" },

    { name: "Uniswap", symbol: "UNIUSD", alt: "UNIUSD" },
    { name: "USD Coin", symbol: "USDCUSD", alt: "USDC" },
    { name: "Tether", symbol: "USDTUSD", alt: "USDT" },

    { name: "VeChain", symbol: "VETUSD", alt: "VETUSD" },

    { name: "Wing Finance", symbol: "WINGUSD", alt: "WINGUSD" },

    { name: "Chain", symbol: "XCNUSD", alt: "XCNUSD" },
    { name: "Stellar", symbol: "XLMUSD", alt: "XLM" },
    { name: "Monero", symbol: "XMRUSD", alt: "XMR" },
    { name: "Proton", symbol: "XPRUSD", alt: "XPRUSDT" },
    { name: "Tezos", symbol: "XTZUSD", alt: "XTZ" },

    { name: "Zcash", symbol: "ZECUSD", alt: "ZEXUSDT" },
    { name: "Zignaly", symbol: "ZIGUSD", alt: "ZIGUSDT" },
  ];

  const handleTypeChange = (e) => {
    const type = e.target.value;
    switch (type) {
      case "Crypto":
        setTradeType("Crypto");
        break;
      case "Forex":
        setTradeType("Forex");
        break;
      case "Stocks":
        setTradeType("Stocks");
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Card>
        <TitleText className="top">Trade</TitleText>
        <ContentWrapper>
          {/* <Switches style={{ width: "100%" }}>
            <button className="active">Trade</button>
            <button>Convert</button>
          </Switches> */}

          {/* Type -- select*/}
          {/* action --- select*/}
          {/* Pair -- select */}
          {/* amount -- amount */}

          <ActionButtons>
            <button
              value="buy"
              className={action === "buy" ? "action buy" : "action"}
              onClick={(e) => {
                setAction(e.target.value);
              }}
            >
              BUY
            </button>
            <button
              value="sell"
              className={action === "sell" ? "action sell" : "action"}
              onClick={(e) => {
                setAction(e.target.value);
              }}
            >
              SELL
            </button>
          </ActionButtons>

          {/* <OutlinedBox style={{ display: "flex" }}>
            <p className="infot">
              Your current account is {currentAccount}.
              <span
                className="clickable"
                style={{ display: "inline", marginLeft: "0.3rem" }}
                onClick={switchAccount}
              >
                Switch
              </span>
            </p>
          </OutlinedBox> */}

          <div className="selectWrap">
            <label htmlFor="type">Type</label>
            <div className="selectBox">
              <div className="selectTop">
                {/* <span> */}
                <select name="type" onChange={handleTypeChange}>
                  {tradeTypes.map((type) => (
                    <option key={type}>{type}</option>
                  ))}
                </select>
                <img src="/extraicons/arrowdown.svg" alt="select" />
                {/* </span> */}
              </div>
            </div>
          </div>
          {tradeType === "Crypto" && (
            <Crypto action={action} assets={cryptoassets} />
          )}
          {tradeType === "Forex" && (
            <Forex action={action} didReset={didReset} />
          )}
          {tradeType === "Stocks" && (
            <Stocks action={action} assets={stockassets} />
          )}
        </ContentWrapper>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0.5rem;
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding: 1rem;
  display: grid;
  gap: 0.8rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      background-color: #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
`;

export default NewTrade;
