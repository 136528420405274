import styled from "styled-components";
import { Header, Sidebar } from "../components/index";
import { useContext, useEffect, useRef, useState } from "react";
import {
  TitleText,
  SubText,
  Content,
  DescBox,
  NeutralButton,
  RedButton,
  Card,
  Switches,
  OutlinedBox,
  FeatureText,
} from "../styles";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Loader from "./Loader";
import { storage } from "../firebase/firebase";
import { userContext } from "../context/userContext";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useMediaQuery } from "@mui/material";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import ModalImage from "react-modal-image";
import {
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import DeleteAccountModal from "./DeleteAccountModal";
import { countryFlags } from "../static";

const Profile = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(user !== null ? false : true);
  const { userData } = useContext(userContext);
  const options = ["Profile", "Security"];
  const [activeOption, setActiveOption] = useState("Profile");
  const [picture, setPicture] = useState("");
  const imageRef = useRef();
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [email, setEmail] = useState(userData?.email);
  const [firstName, setFirstName] = useState(userData?.firstname);
  const [lastName, setLastName] = useState(userData?.lastname);
  const [country, setCountry] = useState(userData?.country);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [selectedDeleteAccount, setSelectedDeleteAccount] = useState(false);
  const [isVerification, setIsVerification] = useState(false);

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  // useEffect(() => {
  //   if (user) {
  //     onSnapshot(doc(db, "users", user.uid), (doc) => {
  //       const data = doc.data();

  //       if (data && !data.verified) {
  //         navigate("/newverify");
  //         toast.error("Verify your account to continue.");
  //       }
  //     });
  //   }
  // }, [user]);

  function handleVerify() {
    setIsVerification(true);

    setTimeout(() => {
      setIsVerification(false);
      navigate("/verify");
    }, 1500);
  }

  async function uploadPicture(newURL) {
    const q = doc(db, "users", user.uid);
    try {
      await updateDoc(q, {
        photoURL: newURL,
      });
      toast.success(
        "Updated successfully. Changes will reflect on your next login."
      );
    } catch (error) {
      console.log(error);
    }
  }

  function handleImageChange() {
    imageRef.current.click();
    setIsUploadingImage(true);

    // setTimeout(() => {
    // setIsUploadingImage(false);
    // }, 8000);
  }

  function handleImageURL(e) {
    const imageLink = e.target.files[0];

    if (imageLink) {
      const url = URL.createObjectURL(imageLink);
      setPicture(url);
      console.log(imageLink);
      const storageRef = ref(storage, imageLink.name + new Date());
      const uploadTask = uploadBytesResumable(storageRef, imageLink);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          setIsUploadingImage(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setIsUploadingImage(false);
            uploadPicture(downloadURL);
          });
        }
      );
    }
  }

  // useEffect(() => {
  // function getPicture() {
  //   const url = URL.createObjectURL(image);
  //   if (!url) {
  //     console.log("loading...");
  //   } else {
  //     console.log("loaded!");
  //   }
  //   setPicture(url);
  //   URL.revokeObjectURL(image);
  // }

  //   if (image) {
  //     getPicture();
  //   }
  //   return () => {
  //     URL.revokeObjectURL(image);
  //   };
  // }, [image, picture]);

  // useEffect(() => {
  //   if (userData) {
  //     setEmail(userData.email);
  //     setCountry(userData.country);
  //     setFirstName(userData.firstname);
  //     setLastName(userData.lastname);
  //   }
  // }, []);

  function handleFirstNameChange(e) {
    const name = e.target.value;

    if (name.length > 0) {
      setFirstName(name);
    } else {
      setFirstName(userData.firstname);
    }
  }

  function handleLastNameChange(e) {
    const name = e.target.value;

    if (name.length > 0) {
      setLastName(name);
    } else {
      setLastName(userData.lastName);
    }
  }

  function handleEmailChange(e) {
    const name = e.target.value;

    var mailformat =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (name.length > 0 && name.match(mailformat)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    if (name.length > 0) {
      setEmail(name);
    } else {
      setEmail(userData.email);
      setEmailError(false);
    }
  }

  function handleCountryChange(e) {
    const coun = e.target.value;

    if (coun.length > 0) {
      setCountry(coun);
    } else {
      setCountry(userData.country);
    }
  }

  function handleCurrentPasswordChange(e) {
    const pass = e.target.value;

    if (pass.length > 0) {
      setCurrentPassword(pass);
    } else {
      setCurrentPassword("");
    }
  }

  function handleNewPassword(e) {
    const pass = e.target.value;

    if (pass.length > 0) {
      setNewPassword(pass);
    } else {
      setNewPassword("");
    }
  }

  function handleConfirmPassword(e) {
    const pass = e.target.value;

    if (pass.length > 0) {
      setConfirmPassword(pass);
    } else {
      setConfirmPassword("");
    }
  }

  async function changeProfile() {
    const profile = doc(db, "users", user.uid);
    await updateDoc(profile, {
      firstname: firstName ? firstName : userData.firstname,
      lastname: lastName ? lastName : userData.lastname,
      country: country ? country : userData.country,
    })
      .then(() => {
        toast.success(
          "Updated successfully, changes will reflect on next login."
        );
        resetProfile();
        setIsSavingProfile(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setIsSavingProfile(false);
      });
  }

  function changeEmail(email) {
    updateEmail(user, email)
      .then(() => {
        changeProfile();
      })
      .catch((error) => {
        if (error.message === "Firebase: Error (auth/email-already-in-use).") {
          toast.error("The selected email is currently unavailable.");
        }
        if (error.message === "Firebase: Error (auth/requires-recent-login).") {
          toast.error(
            "We require a recent login to make updates. \n \n Please re-login and try again."
          );
        } else {
          toast.error(error.message);
          console.log(error.message);
        }
        setIsSavingProfile(false);
        // ...
      });
  }

  function changePassword() {
    if (!newPassword === confirmPassword) {
      toast.error(
        "Please make sure your new password and the confirmation match."
      );
    } else {
      updatePassword(user, newPassword)
        .then(() => {
          toast.success("Password Updated Successfully");
          resetSecurity();
          setIsChangingPassword(false);
        })
        .catch((error) => {
          toast.error(error.message);
          toast.error("There was an error. \n \n Please try again later.");
          setIsChangingPassword(false);
        });
    }
  }

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const countryRef = useRef();
  const currentPassRef = useRef();
  const newPassRef = useRef();
  const confirmPassRef = useRef();

  function resetSecurity() {
    if (currentPassRef) {
      currentPassRef.current.value = "";
    }

    if (newPassRef) {
      newPassRef.current.value = "";
    }

    if (confirmPassRef) {
      confirmPassRef.current.value = "";
    }
  }
  function resetProfile() {
    if (firstNameRef) {
      firstNameRef.current.value = "";
    }

    if (lastNameRef) {
      lastNameRef.current.value = "";
    }

    if (emailRef) {
      emailRef.current.value = "";
    }

    if (countryRef) {
      countryRef.current.value = "";
    }
  }

  function authenticate() {
    signInWithEmailAndPassword(auth, email, currentPassword)
      .then((user) => {
        if (user) {
          changePassword();
        }
      })
      .catch((error) => {
        if (error.message === "Firebase: Error (auth/wrong-password).") {
          toast.error("Your current password is incorrect.");
        }

        if (error.message === "Firebase: Error (auth/user-token-expired).") {
          toast.error(
            "Your session has expired. \n \n Try again on your next login"
          );
        } else {
          toast.error(error.message);
        }
        setIsChangingPassword(false);
      });
  }

  function handlePasswordChange() {
    setIsChangingPassword(true);
    authenticate();
  }

  function handleProfileSave(e) {
    e.preventDefault();

    setIsSavingProfile(true);
    changeEmail(email);

    // }, 2000);
  }

  function handleDeleteAccount() {
    setSelectedDeleteAccount(true);

    setTimeout(() => {
      setSelectedDeleteAccount(false);
    }, 3000);

    setShowDeleteAccountModal(true);
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <DeleteAccountModal
            open={{ showDeleteAccountModal, setShowDeleteAccountModal }}
            user={user}
          />
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active=""
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              <TitleText>Profile</TitleText>
              <ThreeColumnLayout>
                {/* <Wrapper> */}
                <div className="cards">
                  <Card
                    style={{ height: "fit-content", paddingBottom: "0.7rem" }}
                  >
                    <SubText className="top">About</SubText>
                    <div className="about">
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "flex-start",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        {userData?.photoURL && (
                          <img
                            src={userData?.photoURL}
                            alt="user"
                            style={{
                              pointerEvents: "none",
                              // position: "absolute",
                            }}
                            className="profile"
                          />
                        )}

                        <ModalImage
                          large={userData.photoURL}
                          alt="profile"
                          className="modal"
                        />

                        {!userData.photoURL && (
                          <>
                            {!userData.firstname ? (
                              <img
                                src="/svg-loaders/tail-spin.svg"
                                alt="loading"
                              />
                            ) : (
                              <span className="avatar">
                                {userData.firstname?.slice(0, 1)}
                              </span>
                            )}
                          </>
                        )}
                      </div>

                      <div className="desc">
                        <p style={{ fontWeight: "bolder", fontSize: "18px" }}>
                          {userData.firstname} {userData.lastname}{" "}
                        </p>
                        <p>{userData.email} </p>
                        <p>{userData.phonenumber && userData.phonenumber} </p>
                      </div>
                    </div>
                  </Card>

                  {userData.accountType && (
                    <Card>
                      <SubText className="top">Account</SubText>
                      <div className="body" style={{ padding: "1rem" }}>
                        <FeatureText
                          style={{ display: "grid", gap: "0.45rem" }}
                        >
                          <p className="blue">Account Type</p>
                          <span style={{ fontSize: "larger" }}>
                            {userData.accountType}
                          </span>
                        </FeatureText>
                      </div>
                    </Card>
                  )}

                  <Card>
                    <SubText className="top">Actions</SubText>
                    <div className="actions">
                      <DescBox>
                        <p>Update profile photo</p>
                        <div>
                          <NeutralButton
                            disabled={isUploadingImage}
                            onClick={handleImageChange}
                            style={{
                              display: "flex",
                              textAlign: "left",
                            }}
                          >
                            {isUploadingImage ? (
                              <span
                                className="flex w-full"
                                style={{ placeContent: "center" }}
                              >
                                <img
                                  src="/svg-loaders/tail-spin.svg"
                                  alt="loading"
                                  width={24}
                                  height={24}
                                />
                              </span>
                            ) : (
                              <p>Tap to change your profile photo</p>
                            )}
                          </NeutralButton>
                          <input
                            onChange={handleImageURL}
                            type="file"
                            style={{ opacity: "0", position: "absolute" }}
                            ref={imageRef}
                          />
                        </div>
                      </DescBox>

                      {/* <DescBox>
                        <p>Delete account</p>
                        <div>
                          <RedButton
                            disabled={selectedDeleteAccount}
                            onClick={handleDeleteAccount}
                            style={{
                              display: "flex",
                              textAlign: "left",
                            }}
                          >
                            {selectedDeleteAccount ? (
                              <span
                                className="flex w-full"
                                style={{ placeContent: "center" }}
                              >
                                <img
                                  src="/svg-loaders/tail-spin.svg"
                                  alt="loading"
                                  width={24}
                                  height={24}
                                />
                              </span>
                            ) : (
                              <p
                                style={{
                                  color: "white",
                                }}
                              >
                                Tap to delete your account
                              </p>
                            )}
                          </RedButton>
                          <input
                            onChange={handleImageURL}
                            type="file"
                            style={{ opacity: "0", position: "absolute" }}
                            ref={imageRef}
                          />
                        </div>
                      </DescBox> */}

                      <DescBox>
                        <p>Verify your accout</p>
                        <div>
                          <NeutralButton
                            disabled={isVerification}
                            onClick={handleVerify}
                            style={{
                              display: "flex",
                              textAlign: "left",
                            }}
                          >
                            {isVerification ? (
                              <span
                                className="flex w-full"
                                style={{ placeContent: "center" }}
                              >
                                <img
                                  src="/svg-loaders/tail-spin.svg"
                                  alt="loading"
                                  width={24}
                                  height={24}
                                />
                              </span>
                            ) : (
                              <p>Tap to request verification</p>
                            )}
                          </NeutralButton>
                        </div>
                      </DescBox>
                    </div>
                  </Card>
                </div>

                <Card>
                  <SubText className="top">Settings</SubText>
                  <div className="settings">
                    <Switches style={{ maxWidth: "280px" }}>
                      {options.map((opt) => (
                        <button
                          value={opt}
                          key={opt}
                          className={activeOption === opt ? "active" : "switch"}
                          onClick={(e) => setActiveOption(e.target.value)}
                        >
                          {opt}
                        </button>
                      ))}
                    </Switches>

                    <br />

                    {activeOption === "Profile" && (
                      <DataForm>
                        <TextInput>
                          <label htmlFor="email">Email</label>
                          <input
                            placeholder={userData.email}
                            type="email"
                            name="email"
                            ref={emailRef}
                            onChange={handleEmailChange}
                          />

                          {emailError && (
                            <>
                              <p className="red">Please enter a valid email</p>
                              <br />
                            </>
                          )}
                        </TextInput>

                        <MultiInput>
                          <TextInput>
                            <label htmlFor="firstname">First Name</label>
                            <input
                              ref={firstNameRef}
                              placeholder={userData.firstname}
                              name="firstname"
                              onChange={handleFirstNameChange}
                            />
                          </TextInput>
                          <TextInput>
                            <label htmlFor="lastname">Last Name</label>
                            <input
                              placeholder={userData.lastname}
                              name="lastname"
                              ref={lastNameRef}
                              onChange={handleLastNameChange}
                            />
                          </TextInput>
                        </MultiInput>

                        <TextInput>
                          <label htmlFor="lastname">Country</label>
                          <input
                            placeholder={userData.country}
                            name="lastname"
                            ref={countryRef}
                            onChange={handleCountryChange}
                          />
                        </TextInput>

                        <OutlinedBox
                          style={{ maxWidth: mobile ? "100%" : "360px" }}
                        >
                          <p className="infot">
                            Leave a field unchanged to retain its value.
                          </p>
                        </OutlinedBox>

                        <button
                          style={{ display: "grid", placeContent: "center" }}
                          type="submit"
                          className={
                            isSavingProfile ||
                            emailError ||
                            !firstName ||
                            !lastName
                              ? "button disabled"
                              : "button submit"
                          }
                          onClick={handleProfileSave}
                          disabled={
                            isSavingProfile ||
                            emailError ||
                            !firstName ||
                            !lastName
                          }
                        >
                          {isSavingProfile ? (
                            <img
                              src="/svg-loaders/tail-spin.svg"
                              alt="loading"
                              width={24}
                              height={24}
                            />
                          ) : (
                            <p>Save</p>
                          )}
                        </button>
                      </DataForm>
                    )}

                    {activeOption === "Security" && (
                      <DataForm>
                        <TextInput>
                          <label htmlFor="current">Current Password</label>
                          <input
                            placeholder="Current Password"
                            name="current"
                            ref={currentPassRef}
                            onChange={handleCurrentPasswordChange}
                            type="password"
                          />
                        </TextInput>

                        <TextInput>
                          <label htmlFor="new">New Password</label>
                          <input
                            placeholder="New Password"
                            name="new"
                            ref={newPassRef}
                            onChange={handleNewPassword}
                            type="password"
                          />
                        </TextInput>

                        <TextInput>
                          <label htmlFor="confirm">Confirm New Password</label>
                          <input
                            placeholder="Confirm New Password"
                            name="new"
                            ref={confirmPassRef}
                            onChange={handleConfirmPassword}
                            type="password"
                          />
                        </TextInput>

                        <button
                          className={
                            isChangingPassword ||
                            !newPassword ||
                            !confirmPassword ||
                            !currentPassword
                              ? "button disabled"
                              : "button submit"
                          }
                          onClick={handlePasswordChange}
                          disabled={
                            isChangingPassword ||
                            !newPassword ||
                            !confirmPassword ||
                            !currentPassword
                          }
                          style={{ display: "grid", placeContent: "center" }}
                        >
                          {isChangingPassword ? (
                            <img
                              src="/svg-loaders/tail-spin.svg"
                              alt="loading"
                              width={24}
                              height={24}
                            />
                          ) : (
                            <p>Save</p>
                          )}
                        </button>
                      </DataForm>
                    )}
                  </div>
                </Card>
                {/* </Wrapper> */}
              </ThreeColumnLayout>
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .avatar {
    width: 45px;
    place-content: center;
    height: 45px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  .content {
    width: 100%;
    box-sizing: border-box;
  }
`;

const ThreeColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 360px auto;
  grid-template-rows: auto auto;
  box-sizing: border-box;
  gap: 1rem;
  height: fit-content;
  margin-top: 50px;
  padding-bottom: 60px;
  width: 100%;
  overflow-x: hidden;

  input:invalid {
    border: 1px solid #e64b60;
  }

  @media screen and (max-width: 900px) {
    display: grid;
    grid-template-columns: auto;
  }

  .settings {
    padding: 1rem;
    display: grid;
    box-sizing: border-box;
  }

  .cards {
    display: grid;
    gap: 2rem;
    margin: 0;
    padding: 0;
  }

  .avatar {
    width: 20px;
    place-content: center;
    height: 20px;
    align-items: center;
    padding: 0.5rem;
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .actions {
    padding: 1rem;
    display: grid;
    gap: 2rem;
  }

  .about {
    display: flex;
    padding: 1rem;
    text-align: left;
    gap: 1rem;
    align-items: center;
    height: max-content;

    .img {
      height: 60px;
      width: 60px;
      object-fit: cover;
      border-radius: 100%;
    }

    .desc {
      display: grid;
      gap: 0.2rem;
    }
  }
`;

const DataForm = styled.form`
  display: grid;
  gap: 1rem;
  max-width: 100%;
  box-sizing: border-box;

  input {
    box-sizing: border-box;
    width: 100%;
  }

  .button {
    max-width: 280px;
    margin-top: 2rem;
    justify-self: right;
    box-sizing: border-box;

    .out {
      width: 280px;
    }

    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }
`;

const MultiInput = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  div {
    width: 100%;
  }

  @media screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
`;

const TextInput = styled.div`
  /* max-width: 360px; */
  box-sizing: border-box;
  display: grid;
  gap: 0.5rem;
  max-width: 100%;

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #a3a3a3;
  }

  input {
    display: grid;
    background: #1f273a;
    border: 1px solid #3a4867;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 1rem;
    font-size: 1rem;
    gap: 1rem;
    color: white;
    outline: none;
  }
`;

export default Profile;
